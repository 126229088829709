<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12.mb-2.title-box
      TimePeriodTab(:tab="statusTab" @tab-change="changeStatusBar")
    
    .col-lg-12
      .card
        .card-body
          .row 
            .col-lg-12
              p.font-weight-normal 單位中心
              .checkbox-group.col-lg-12
                b-form-group
                  b-form-checkbox-group#checkbox-group-1(
                    :checked="searchData.type"
                    @input="changeOption($event)"
                    name="option_checkbox"
                    )
                    b-form-checkbox(
                      v-for="_option in options" 
                      :key="_option.id" 
                      :value="_option.id"
                    ) {{_option.name}}
            .col-lg-12
              p.font-weight-normal 新聞分類
              .checkbox-group.col-lg-12
                b-form-group
                  b-form-checkbox-group#checkbox-group-1(
                    :checked="searchData.type"
                    @input="changeOption($event)"
                    name="option_checkbox"
                    )
                    b-form-checkbox(
                      v-for="_option in options" 
                      :key="_option.id" 
                      :value="_option.id"
                    ) {{_option.name}}
            .col-lg-12
              p.font-weight-normal 稿件來源
              .checkbox-group.col-lg-12
                b-form-group
                  b-form-checkbox-group#checkbox-group-1(
                    :checked="searchData.type"
                    @input="changeOption($event)"
                    name="option_checkbox"
                    )
                    b-form-checkbox(
                      v-for="_option in options" 
                      :key="_option.id" 
                      :value="_option.id"
                    ) {{_option.name}}
            .col-lg-4
              .form-group.mb-3
                label 上稿人員
                br
                multiselect(
                  v-model="currentEditer",
                  :options="editer",
                  placeholder="請選擇人員",
                  label="name",
                  track-by="id",
                  @search-change="searchEditerList",
                  select-label=""
                )
          b-button.width-md(variant="primary") 查詢

    .col-lg-12
      .card-body.py-2
        p 符合條件資料  共 123筆 / 1頁
        ul.list-unstyled 
          li 單位中心(即時中心/要聞中心/即時中心)
          li 新聞分類：全選  
          li 平均單篇PV 1,XXX 

    .col-lg-12
      //- 表格
      .card
        .card-body
          .header-row.mb-3.justify-content-between
            b-button-group.tab-group
              b-button.mr-2(variant="primary" :class="{off: categoryTab !== 0}" @click="changeCategoryTab(0)") 以中心為主
              b-button.mr-2(variant="primary" :class="{off: categoryTab !== 1}" @click="changeCategoryTab(1)") 以人員為主
            b-button.width-md(variant="primary" to="" )
              span 匯出報表  

          //- Table
          .table-responsive.mb-0
            b-table(
              responsive, 
              ref="dataTable",
              :items="getData", 
              :fields="dynamicFields", 
              :per-page="perPage", 
              :current-page="currentPage", 
            )

              //- 排名
              template(v-slot:cell(index)="row") {{ row.index + 1 }}

              //- 人員
              template(v-slot:cell(editer)="row")
                span(v-if="!row.item.editer") -
                b-button.table-btn(v-else :to="`/center-analysis/detail/editer/${row.index}`")
                  span {{ row.item.editer }}

              //- 單位
              template(v-slot:cell(unit)="row")
                span(v-if="!row.item.unit") -
                span(v-if="categoryTab === 1") {{ row.item.unit }}
                b-button.table-btn(v-else :to="`/center-analysis/detail/center/${row.index}`")
                  span {{ row.item.unit }}

              //- 總 PV
              template(v-slot:cell(pv)="row") {{ $root.common.formatNumber(row.item.pv, 0) }}

              //- PV佔比
              template(v-slot:cell(percentage)="row") {{ row.item.percentage }}%

              //- 愛心數
              template(v-slot:cell(like)="row") {{ row.item.like }}

              //- 收藏數
              template(v-slot:cell(favorite)="row") {{ row.item.favorite }}

              //- 總稿件
              template(v-slot:cell(article)="row") {{ $root.common.formatNumber(row.item["article"], 0) }}

              //- 稿件占比
              template(v-slot:cell(article-percentage)="row") {{ row.item["article-percentage"] }}%

              //- 自製稿
              template(v-slot:cell(internal)="row") {{ $root.common.formatNumber(row.item.internal, 0) }} ({{ row.item['internal-percentage'] }}%)

              //- 外稿
              template(v-slot:cell(external)="row") {{ $root.common.formatNumber(row.item.external, 0) }} ({{ row.item["external-percentage"] }}%)

          .row.mb-md-2
            .col-12
              div(class="dataTables_paginate paging_simple_numbers float-right")
                ul.pagination.pagination-rounded.mb-0
                  b-pagination(v-model="currentPage", :total-rows="totalRows", :per-page="perPage")
</template>
    
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import TimePeriodTab from "./components/TimePeriodTab";

const mockData = [
  { 
    editer: '記者1',
    unit: '即時中心',
    pv: 12000,
    average: 12000,
    percentage: 12,
    like: 30,
    favorite: 10,
    article: 12000,
    'article-percentage': 12,
    internal: 60,
    'internal-percentage': 38,
    external: 60,
    'external-percentage': 38,
  }
]

/**
 * Starter component
 */
export default {
  name: 'NewsAnalysis',
  data () {
    return {
      title: "各中心數據查詢 / 數據分析",
      items: [{
        text: "數據分析",
        href: "/dashboard",
      },
      {
        text: "各中心數據查詢",
        active: true,
      }
      ],
      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      sortDesc: false,
      status: false,
      fields: [
        { key: 'index', label: "排名", class: 'width-auto center' },
        { key: 'editer', label: "人員", class: 'width-auto center' },
        { key: 'unit', label: "單位", class: 'width-auto center' },
        { key: "pv", label: "總PV", class: 'width-auto center' },
        { key: "average", label: "平均每篇PV", class: 'width-auto center' },
        { key: "percentage", label: "PV 佔比", class: 'width-auto center' },
        { key: "like", label: "愛心數", class: 'width-auto center' },
        { key: "favorite", label: "收藏數", class: 'width-auto center' },
        { key: "article", label: "總稿件", class: 'width-auto center' },
        { key: "article-percentage", label: "稿件佔比", class: 'width-auto center' },
        { key: "internal", label: "自製稿", class: 'width-auto center' },
        { key: "external", label: "外稿", class: 'width-auto center' }
      ],
      statusTab: 0,
      categoryTab: 0,
      options: [
        { id: 0, name: "全部"},
        { id: 1, name: " 未處理"},
        { id: 2, name: "處理中"},
        { id: 3, name: "已結案"}
      ],
      searchData: {
        unit: [],
        type: [],
        source: [], 
        editer: ''
      },
      editer: [],
      currentEditer: false,
    };
  },
  computed: {
    dynamicFields() {
      return this.fields.filter(_field => this.categoryTab === 0 ? _field.key !== 'editer' : _field)
    }
  },
  methods: {
    // 變更狀態夾
    changeStatusBar (_status) {
      this.statusTab = _status
      this.currentPage = 1
      this.reloadData()
    },
    changeCategoryTab (_category) {
      this.categoryTab = _category
      this.currentPage = 1
      this.reloadData()
    },
    reloadData () {
      this.$refs.dataTable.refresh()
    },
    // 獲得推播項目清單
    getData (_table, _callback) {
      let vm = this
      _callback(mockData)
      // this.$root.apis.getData({
      //   status_type: this.statusTab,
      //   page: this.currentPage
      // }, function (_response) {
      //   let response = _response.body.data
      //   vm.currentPage = response.page
      //   vm.totalRows = response.total
      //   vm.perPage = response.per_page
      //   _callback(response.items)
      // })
    },
    searchEditerList (_text) {
      let vm = this
      console.log("=> 管理員搜尋:", _text)
      this.$root.apis.getFastManagerList({
        name: _text,
        per_page: 100
      }, function (_response) {
        vm.editer = _response.body.data.items
      })
    },
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    TimePeriodTab
  }
};
</script>
  
<style lang="sass" scoped>
.card-header
  background: #ffffff
.label-select
  border: none
  padding: 0
  height: auto
  line-height: 1
  font-weight: 600
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1
.tab-group
  background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
  button.off
    color: rgba(156,163,175,1)    
    background: rgba(0,0,0,0)
    border-color: rgba(0,0,0,0)
::v-deep .width-auto
  min-width: 7rem
  white-space: nowrap
  vertical-align: middle
  &.center
    text-align: center
  
::v-deep .table-btn
  text-decoration: underline
  color: #0073b8
  background: none
  border: none
  padding: 0
  &.delete
    color: #333333
</style>
